/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useCallback } from 'react';
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useRouter } from "next/router";

type Locale = "en" | "es" | "vi" | "zh_Hans";
// TODO: auto redirect and NEXT_LOCALE cookie funcationality. replace outdated overlay

// eslint-disable-next-line react/require-default-props
// export default function LocaleDropdown({ style }: { style?: React.CSSProperties }) {
export default function LocaleDropdown() {
  const router = useRouter();
  const locale: Locale = router.locale?.match(/en|es|vi|zh_Hans/)
    ? (router.locale as Locale)
    : "en";

  const languageNativeNames = {
    en: "English",
    es: "Español",
    vi: "Tiếng Việt",
    zh_Hans: "中文",
  };
  const localeLang = languageNativeNames[locale || "en"];
  const languageToFlagCode = {
    en: "US",
    es: "MX",
    vi: "VN",
    zh_Hans: "CN",
  };
  const localeFlagCode = languageToFlagCode[locale || "en"];

  const hideLanguages = process.env.NEXT_PUBLIC_HIDE_LANGUAGES?.split(",");

  return (
    <Dropdown
      overlay={
        <Menu
          onClick={(info) =>
            router.push(router.asPath, router.asPath, { locale: info.key })
          }
          selectedKeys={[locale || "en"]}
        >
          {!hideLanguages?.includes("en") && (
            <Menu.Item key="en">
              <img
                alt="United States"
                src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                width="20px"
                height="20px"
                style={{ marginRight: 5 }}
              />
              English
            </Menu.Item>
          )}
          {!hideLanguages?.includes("es") && (
            <Menu.Item key="es">
              <img
                alt="Mexico"
                src="https://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg"
                width="20px"
                height="20px"
                style={{ marginRight: 5 }}
              />
              Español
            </Menu.Item>
          )}
          {!hideLanguages?.includes("vi") && (
            <Menu.Item key="vi">
              <img
                alt="Vietnam"
                src="https://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg"
                width="20px"
                height="20px"
                style={{ marginRight: 5 }}
              />
              Tiếng Việt
            </Menu.Item>
          )}
          {!hideLanguages?.includes("zh_Hans") && (
            <Menu.Item key="zh_Hans">
              <img
                alt="China"
                src="https://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg"
                width="20px"
                height="20px"
                style={{ marginRight: 5 }}
              />
              中文
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button>
        <img
          alt="Language Flag"
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${localeFlagCode}.svg`}
          width="20px"
          height="20px"
          style={{ marginRight: "5px" }}
        />
        {localeLang}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/require-default-props */
import type { MenuProps } from "antd";
import { Button, Col, Layout, Menu, Row, Spin } from "antd";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
// import { useMutation } from '@tanstack/react-query';
import {
  ArrowLeftOutlined,
  BookOutlined,
  DashboardOutlined,
  LogoutOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import LayoutBase from "./LayoutBase";
// import { graphql } from '../../__generated__/gql';
// import { errorToString, graphqlClient } from '../../lib/util';
import useDictionary from "../../lib/useDictionary";
import LocaleDropdown from "../LocaleDropdown";
// import { WhoamiQuery } from '../../__generated__/gql/graphql';

interface LayoutDashboardProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  showBackButton?: boolean;
  // user?: WhoamiQuery["whoami"];
}

export default function LayoutDashboard(
  props: LayoutDashboardProps,
): JSX.Element {
  const {
    children,
    title,
    description,
    isLoading,
    showBackButton = false,
    // user,
  } = props;

  const d = useDictionary();
  const router = useRouter();

  const items: MenuProps["items"] = [
    {
      label: <Link href="/">{d.menu.Dashboard}</Link>,
      key: "/",
      icon: <DashboardOutlined />,
    },
    {
      label: <Link href="/programs">{d.menu.Programs}</Link>,
      key: `/programs`,
      icon: <PaperClipOutlined />,
    },
    {
      label: <Link href="/enrollments">{d.menu.Enrollments}</Link>,
      key: `/enrollments`,
      icon: <BookOutlined />,
    },
    {
      label: d.menu.SignOut,
      key: "signOut",
      icon: <LogoutOutlined />,
    },
  ];

  const handleSignOut = async () => {
    await signOut({ callbackUrl: "/sign-in" });
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "signOut") handleSignOut();
  };

  return (
    <LayoutBase title={title} description={description}>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout.Sider breakpoint="lg" collapsedWidth="0" theme="light">
          <Menu
            theme="light"
            mode="inline"
            onClick={handleMenuClick}
            items={items}
            defaultSelectedKeys={[router.asPath]}
          />
        </Layout.Sider>
        <Layout>
          <Layout.Content style={{ margin: 16 }}>
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              {showBackButton && (
                <Col>
                  <Button
                    onClick={() => router.back()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Back
                  </Button>
                </Col>
              )}
              <Col>
                <LocaleDropdown />
              </Col>
            </Row>
            <Spin spinning={!!isLoading} style={{ width: "100%" }}>
              {children}
            </Spin>
          </Layout.Content>
          <Layout.Footer
            style={{
              textAlign: "center",
              color: "rgba(0,0,0,.45)",
              padding: 24,
            }}
          >
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <div>Copyright {new Date().getFullYear()} Attendly</div>
          </Layout.Footer>
        </Layout>
      </Layout>
    </LayoutBase>
  );
}
